const TOTAL_SLIDES = 6;  // Number of slides in the carousel
var currentSlideIndex = 0;
var delay = false;
var currentTouchY;
var lastTouchY;

// Utility function to handle modular arithmetic
function mod(x, m) {
    return (x % m + m) % m;
}

// Updates the carousel based on the current index
function updateCarousel(index) {
    if (document.getElementById(`carousel-slide-${index}`) === null) {
        return;
    }

    // Update navigation arrow and dots
    const degree = index * 360/TOTAL_SLIDES;
    const arrow = document.getElementById('carousel-nav-arrow');
    
    // Remove previous degree
    for (let i = 0; i < TOTAL_SLIDES; i++) {
        arrow.style.transform = '';
    }

    // Add new degree
    arrow.style = `transform: rotate(calc(-90deg + ${degree}deg  ));`;
    
    // Remove 'dot-active' class from all dots
    document.querySelectorAll('.dot').forEach(dot => {
        dot.classList.remove('dot-active');
    });
    
    // Add 'dot-active' class to the current dot
    const activeDot = document.querySelector(`.dot-${index}`);
    if (activeDot) {
        activeDot.classList.add('dot-active');
    }

    // Hide all slides and show the current one
    for (let i = 0; i < TOTAL_SLIDES; i++) {
        document.getElementById(`carousel-slide-${i}`).classList.add('carousel-slide-hidden');
    }
    document.getElementById(`carousel-slide-${index}`).classList.remove('carousel-slide-hidden');

    // Helper function to update element classes
    function updateElementClasses(position, imageClass, headerClass, locationClass) {
        const slide = document.getElementById(`carousel-slide-${mod(index + position, TOTAL_SLIDES)}`);
        const image = slide.querySelector('.carousel-slide-image img');
        const header = slide.querySelector('.carousel-slide-header h1');
        const location = slide.querySelector('.carousel-slide-location div');

        image.classList.remove('carousel-slide-element-above', 'carousel-slide-element-under', 'carousel-slide-element-center');
        header.classList.remove('carousel-slide-element-above', 'carousel-slide-element-under', 'carousel-slide-element-center');
        location.classList.remove('carousel-slide-element-above', 'carousel-slide-element-under', 'carousel-slide-element-center');

        image.classList.add(imageClass);
        header.classList.add(headerClass);
        location.classList.add(locationClass);
    }

    // Apply classes to each slide based on their position relative to the current slide
    for (let i = -3; i <= 3; i++) {
        let position = i;
        const isCenter = position === 0;
        const isAbove = position < 0 && position >= -2;
        const isUnder = position > 0 && position <= 2;

        updateElementClasses(position, 
                              isAbove ? 'carousel-slide-element-above' : isCenter ? 'carousel-slide-element-center' : 'carousel-slide-element-under',
                              isAbove ? 'carousel-slide-element-under' : isCenter ? 'carousel-slide-element-center' : 'carousel-slide-element-above',
                              isAbove ? 'carousel-slide-element-under' : isCenter ? 'carousel-slide-element-center' : 'carousel-slide-element-above');
    }

    currentSlideIndex = index;
}

var timer = setInterval(() => {
    updateCarousel(mod(currentSlideIndex + 1, TOTAL_SLIDES));
}, 5000);

// Handle slide scrolling via mouse wheel or touch events
function scrollSlide(e) {
    e.preventDefault();
    e.stopPropagation();

    if (delay) return;

    clearInterval(timer);
    timer = setInterval(() => {
        updateCarousel(mod(currentSlideIndex + 1, TOTAL_SLIDES));
    }, 5000);

    let didChange = false;

    if (e.type === "wheel") {
        if (e.deltaY > 0) {
            updateCarousel(mod(currentSlideIndex + 1, TOTAL_SLIDES));
            didChange = true;
        } else if (e.deltaY < 0) {
            updateCarousel(mod(currentSlideIndex - 1, TOTAL_SLIDES));
            didChange = true;
        }
    } else if (e.type === "touchmove") {
        if (currentTouchY === undefined) {
            currentTouchY = e.touches[0].pageY;
        }
        lastTouchY = currentTouchY;
        currentTouchY = e.touches[0].pageY;

        const delta = lastTouchY - currentTouchY;
        if (delta > 0) {
            updateCarousel(mod(currentSlideIndex + 1, TOTAL_SLIDES));
            didChange = true;
        } else if (delta < 0) {
            updateCarousel(mod(currentSlideIndex - 1, TOTAL_SLIDES));
            didChange = true;
        }
    }

    if (didChange) {
        delay = true;
        setTimeout(() => {
            delay = false;
            lastTouchY = undefined;
            currentTouchY = undefined;
        }, 2000);
    }
}

// Add event listeners for scroll and touch events
//window.addEventListener('wheel', scrollSlide);
//window.addEventListener('touchmove', scrollSlide);
